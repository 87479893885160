// Application Service URL
export const applicationServiceUrl = process.env.REACT_APP_APPLICATION_SERVICE_URL;

// Discovery
export const getDiscoveryUrl = `${applicationServiceUrl}/v1/discovery`;

// Experiences
export const getExperienceUrl = `${applicationServiceUrl}/v1/experiences/:experience_id`;

// Search
export const getSearchSearchableTermsUrl = `${applicationServiceUrl}/v1/search/searchable_terms`;
export const createSearchUrl = `${applicationServiceUrl}/v1/search`;
