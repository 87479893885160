import React, { useCallback, useState } from "react";
import { Button, Container, Grid, GridColumn, GridRow, Image, Search } from "semantic-ui-react";

import * as ROUTES from "../../constants/routes";
import { Link } from "react-router-dom";
import { OTT_PINK_LOGO } from "../../assets/Logos";

import "./styles.css";

const NavMenu = () => {
  const navMenuLinks = [
    {
      name: "How It Works?",
      href: "/how-it-works",
    },
    {
      name: "Blog",
      href: "/blog",
    },
    {
      name: "Business",
      href: "/business",
    },
  ];

  return (
    <GridRow
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        border: "none",
        alignItems: "center",
        marginRight: "100px",
      }}
    >
      {navMenuLinks.map((link, index) => (
        <GridColumn key={index}>
          <Button
            style={{
              backgroundColor: "var(--header-backgound-color)",
              color: "var(--primary-text-color)",
              fontWeight: 500,
              fontStyle: "normal",
            }}
            href={link.href}
          >
            {link.name}
          </Button>
        </GridColumn>
      ))}

      <GridColumn>
        <Button
          style={{
            backgroundColor: "var(--green-color)",
            borderRadius: "50px",
            color: "var(--background-color)",
            fontWeight: 500,
            fontStyle: "normal",
            lineHeight: 1.13,
          }}
          href="https://www.onetwotrail.com/download"
        >
          Download
        </Button>
      </GridColumn>
    </GridRow>
  );
};

const Header = ({
  searchQuery = null,
  searchableTerms = null,
  onSearch = null,
  onSearchChange = null,
  onReset = null,
  showReset = false,
}) => {
  // Add state management for search
  const [value, setValue] = useState(searchQuery ?? "");
  const [isLoading, setIsLoading] = useState(false);
  const [results, setResults] = useState([]);

  // Add state for controlling search open state
  const [isOpen, setIsOpen] = useState(false);

  // Convert searchable terms to the required format only if they exist
  const searchResults =
    searchableTerms?.map((term) => ({
      title: term,
    })) || [];

  // Add handler for Enter key
  const handleKeyPress = useCallback(
    (e) => {
      if (e.key === "Enter") {
        onSearch && onSearch(value); // Use the exact input value
        setIsOpen(false);
      }
    },
    [value, onSearch],
  );

  // This handler now only updates local state
  const handleSearchChange = useCallback(
    (e, { value }) => {
      setIsOpen(true);
      setIsLoading(true);
      setValue(value);

      if (onSearchChange) {
        onSearchChange(value);
      }

      const filteredResults = searchResults.filter((item) => item.title.toLowerCase().includes(value.toLowerCase()));

      setResults(filteredResults);
      setIsLoading(false);
    },
    [searchResults],
  );

  const handleResultSelect = useCallback(
    (e, { result }) => {
      setValue(result.title);
      onSearch && onSearch(result.title);
      setIsOpen(false);
    },
    [onSearch],
  );

  return (
    <>
      <Container
        style={{
          marginBottom: "20px",
          width: "100vw",
          height: "100%",
          backgroundColor: "var(--header-backgound-color)",
          padding: "10px 0",
          border: "none",
          borderWidth: "0",
          position: "sticky",
          top: "0",
          zIndex: 1000,
        }}
      >
        <Grid columns={2} style={{ display: "flex", alignItems: "center" }}>
          <GridColumn
            computer={6}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: "none",
              gap: "20px",
              paddingLeft: "40px",
            }}
          >
            <Link to={ROUTES.ROOT}>
              <Image
                src={OTT_PINK_LOGO}
                style={{
                  maxHeight: "50px",
                  objectFit: "cover",
                }}
              />
            </Link>

            {searchableTerms && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "540px",
                  position: "relative",
                }}
              >
                <Search
                  loading={isLoading}
                  onResultSelect={handleResultSelect}
                  onSearchChange={handleSearchChange}
                  onKeyPress={handleKeyPress}
                  onBlur={() => setIsOpen(false)}
                  results={results}
                  value={value}
                  aligned="left"
                  open={isOpen}
                  style={{ width: "100%" }}
                  input={{
                    icon: "search",
                    iconPosition: "left",
                    placeholder: "Search..",
                  }}
                />
                {showReset && value && (
                  <i
                    className="close icon"
                    style={{
                      position: "absolute",
                      right: "10px",
                      cursor: "pointer",
                      zIndex: 2,
                      color: "var(--pink-color)",
                      fontSize: "1.2em", // Increased size
                      top: "50%",
                      transform: "translateY(-50%)", // Center vertically
                    }}
                    onClick={() => {
                      setValue("");
                      onReset && onReset();
                    }}
                  />
                )}
              </div>
            )}
          </GridColumn>
          <GridColumn computer={10} floated="right">
            <NavMenu />
          </GridColumn>
        </Grid>
      </Container>
    </>
  );
};

export default Header;
